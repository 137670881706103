import { render, staticRenderFns } from "./RegionView.vue?vue&type=template&id=5db24897&scoped=true"
import script from "./RegionView.vue?vue&type=script&lang=js"
export * from "./RegionView.vue?vue&type=script&lang=js"
import style0 from "./RegionView.vue?vue&type=style&index=0&id=5db24897&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db24897",
  null
  
)

export default component.exports